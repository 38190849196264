<template>
  <div class="app-footer">
    <div class="container">
      <nav>
        <!-- Zircuit Stats -->
        <div class="testnet-stats">
          <!-- Blink Dot -->
          <div class="blink"></div>

          <!-- Tx Count -->
          <strong>
            <number
              class="bold transition"
              :from="txCount"
              :to="txCount"
              :duration="2"
              easing="Power4.easeOut"
            />
          </strong>
          <a href="https://explorer.zircuit.com/" target="_blank">Testnet Transacations</a>

          <!-- Add Network -->
          <template v-if="addNetworkButtonRequired">
            <AddNetwork v-if="userStore.provider" />
          </template>
        </div>

        <!-- Footer Menu -->
        <div class="app-menu">
          <!-- X Share -->
          <a 
            target="_blank"
            class="twitter-share-button"
            :href="shareText"
            data-size="large">
            Share
          </a>

          <!-- X -->
          <a :href="`https://twitter.com/${twitterHandle}`" target="_blank">
            <img src="/img/icons/x.svg" />
          </a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import MintMixin from '@/mixins/mintMixin'
import AddNetwork from '@/components/common/AddNetwork.vue'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    AddNetwork,
  }
})
export default class AppNav extends Mixins(MintMixin) {
  txCount = 0

  get apiUrl() {
    return 'https://testnetscan-api.zircuit.com/v1'
  }

  get shareText() {
    return 'https://twitter.com/intent/tweet?text=Most%20purrsome%20Badges%20on%20%40ZircuitL2%20testnet%20by%20%40PurrPics%2C%20meow%21%20https%3A%2F%2Fpurr.pics&url=https://purr.pics&hashtags=zircuit,purrpics'
  }

  async fetchTxCount() {
    const url = `${this.apiUrl}/transaction-stats`
    const result = await axios(url)

    this.txCount = result.data.totalTxCount
  }

  async mounted() {
    await this.fetchTxCount()

    setInterval(this.fetchTxCount, 10000)
  }
}
</script>

<style lang="scss">
.app-footer {
  background-color: #f4f1e5;
  border-top: 1px solid #dddacc;
  position: sticky;
  z-index: 3;

  .testnet-stats {
    display: flex;
    align-items: center;

    a:hover {
      text-decoration: underline;
    }

    .blink {
      animation: blinker 2s linear infinite;
      width: 10px;
      height: 10px;
      display: inline-block;
      background-color: #0cbd2f;
      border-radius: 100%;
    }

    strong {
      display: inline-block;
      width: 75px;
      margin: 0 0 0 .5rem;
    }

    button {
      margin-left: 2rem;
    }
  }
  
  @keyframes blinker {
    50% {
      opacity: 0.6;
    }
  }

  nav {
    padding: 1.25rem 0;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .app-menu {
      display: flex;
      align-items: center;

      a {
        margin: 0 1.25rem 0 .5rem;
      }
      
      .twitter-share-button {
        padding: .5rem 1.25rem;
        border-radius: 12px;
        background: linear-gradient(90deg, rgba(192, 173, 0, 0.1) -10.2%, rgba(20, 182, 0, 0.1) 47.34%, rgba(0, 126, 73, 0.1) 109.67%);
      }
    }

    a {
      color: #333;
      line-height: 1;
      text-decoration: none;
    }

    img {
      width: 20px;
    }
  }
}
</style>