import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AnimationsMixin extends Vue {
  mounted() {
    this.$nextTick(async () => {
      this.initConfetti()
    })
  }

  initConfetti() {
    document.addEventListener('click', event => {
      this.throwConfetti(event.x, event.y)
    })
  }
  
  throwConfetti(x: number, y: number, amount = 3, size = 24, radius = 250, duration = 2000) {
    const df = document.createDocumentFragment()
    
    for (let i = 0; i < amount; i++) {
      const c = document.createElement('i')
      
      c.style.cssText = `
        transform: translate3d(${(this.random(radius) - radius / 2)}px, ${(this.random(radius) - radius / 2)}px, 0)
        rotate(${ this.random(360) }deg); 
      `;
      c.style.left = x + 'px'
      c.style.top = y + 'px'
      c.style.width = size + 'px'
      c.style.height = size + 'px'
      c.style.animationDuration = duration / 2 + 'ms'
      
      df.appendChild(c)
      setTimeout(() => {
        c.remove()
      }, duration)  
    }

    document.getElementById('app')?.appendChild(df)
  }

  random(max: number){
    return Math.random() * (max - 0) + 0;
  }

  onDialogShow() {
    const x = window.innerWidth / 2
    const y = window.innerHeight / 2
    const count = 50
    const size = 50
    const radius = 750
    const duration = 2500
    
    this.throwConfetti(x, y, count, size, radius, duration)
  }
}