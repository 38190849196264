import RootStore from '@/store'
import Bus from '@/providers/bus'
import { Route } from '@/types/app'
import UiStore from '@/store/uiStore'
import UserStore from '@/store/userStore'
import { ChainId } from '@/types/blockchain'
import { getModule } from 'vuex-module-decorators'
import { Component, Vue } from 'vue-property-decorator'
import { ApiProvider } from '@/providers/api'
import BlockchainProvider from '@/providers/blockchain'

const uiStore = getModule(UiStore, RootStore)
const userStore = getModule(UserStore, RootStore)

@Component
export default class BaseMixin extends Vue {
  get bus() {
    return Bus
  }

  get twitterHandle() {
    return 'PurrPics'
  }

  get refCode() {
    return process.env.VUE_APP_ZIRCUIT_REF_CODE
  }

  get refUrl() {
    return `https://stake.zircuit.com/?ref=${this.refCode}`
  }

  get uiStore(): UiStore {
    return uiStore
  }
  
  get userStore(): UserStore {
    return userStore
  }

  get api() {
    return new ApiProvider()
  }

  get blockchain() {
    return new BlockchainProvider()
  }

  redirect(route: Route, param = '') {
    if (this.$route.path === route) return

    this.$router.push(`/${route}/${param}`)
  }

  showError(summary: string) {
    // eslint-disable-next-line
    (this as any).$toast.add({
      summary,
      severity: 'error', 
      group: 'errors', 
      life: 30000
    })
  }

  getChainById(id: ChainId) {
    return ChainId[id]
  }
}