export enum Route {
  HOME = 'home',
  DASHBOARD = 'dashboard',
  PROFILE = 'profile',
  ACTIVITY = 'activity',
  COLLECTION = 'collection',
}

export enum EligibilityStatus {
  NotEligible = 0,
  Eligible = 1,
  Owned = 2,
}

export type NftItem = {
  tokenId: number
  icon: string
  name: string
  desc: string
  eligible: EligibilityStatus
  supply: number
}
export enum ZircuitStakingEvent {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
}

export type ZircuitStakingDepositGrapItem = {
  id: string
  token: string
  depositor: string 
  amount: string
  transactionHash: string
}

export type ZircuitStakingDeposit = [
  eventId: string,
  depositor: string,
  token: string,
  amount: string,
  payload: {
    log: {
      transactionHash: string
    }
  }
]

export type ZircuitStakingWithdraw = [
  eventId: string,
  withdrawer: string,
  token: string,
  amount: string,
  payload: {
    log: {
      transactionHash: string
    }
  }
]

export type ZircuitStakingCustomEvent = {
  id: number
  address: string
  token: string
  amount: string
  type: string
  timestamp: number
  shift: number
  animationDuration: number
  cloud: number
  payload: {
    log: {
      transactionHash: string
    }
  }
  paused: boolean
  initial?: boolean
}