import { ChainId } from "@/types/blockchain"
import { JsonRpcProvider, formatUnits } from "ethers"

const RPC_URL: Record<number, string> = {
  [ChainId.Ethereum]: 'https://rpc.ankr.com/eth'  
}

export default class BlockchainProvider {
  #chain = ChainId.Ethereum

  get rpcUrl() {
    return RPC_URL[this.chain]
  }

  get provider() {
    return new JsonRpcProvider(this.rpcUrl)
  }

  get chain() {
    return this.#chain
  }

  set chain(payload: ChainId) {
    this.#chain = payload
  }

  async getGasPrice() {
    const { gasPrice } = await this.provider.getFeeData()

    return formatUnits(gasPrice || 0, 'gwei')
  }
}