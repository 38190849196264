<template>
  <ProgressSpinner class="app-loading" v-show="uiStore.loading" />
</template>

<script lang="ts">
import BaseMixin from '@/mixins/baseMixin'
import ProgressSpinner from 'primevue/progressspinner'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    ProgressSpinner
  }
})
export default class AppLoading extends Mixins(BaseMixin) {}
</script>

<style lang="scss">
.app-loading {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #f4f1e5b5;

  svg {
    width: 64px;
  }
}
</style>