export enum ChainId {
  Ethereum = 1
}

export type Chain = {
  id: ChainId
  slug: string
  name: string
  symbol: string
  rpc_url: string
}