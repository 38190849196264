<template>
  <span v-on="$listeners" v-bind="$attrs">{{ tweenedNumber }}</span>
</template>

<script lang="ts">
import gsap, { TweenLite } from 'gsap'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'NumberAnimation'
})
export default class NumberAnimation extends Vue {
  fromProp = '0'
  tLite: gsap.core.Tween | null = null

  @Prop({ default: 0 }) from!: number
  @Prop({ default: 0 }) to!: number
  @Prop({ default: (num: string) => parseInt(num) }) format!: (num: string) => number
  @Prop({ default: 1 }) duration!: number
  @Prop({ default: 'Power1.easeOut' }) easing!: string
  @Prop({ default: 0 }) delay!: number
  @Prop({ default: false}) animationPaused!: boolean
  
  get tweenedNumber () {
    return this.format(this.fromProp)
  }

  @Watch('to')
  onToChange (newValue: number) {
    this.tween(newValue)
  }

  countFullstops (str: string) {
    return str.replace(/[^.]/g, '').length
  } 
  
  tween (value: number) {
    const tLite = TweenLite
      .to(this.$data, this.duration, {
        fromProp: value,
        paused: this.animationPaused,
        ease: this.easeCheck(),
        // @ts-expect-error fuck you
        onStart: () => this.$emit('start'),
        // @ts-expect-error fuck you
        onComplete: () => this.$emit('complete'),
        onUpdate: () => {
          this.fromProp = Number(this.fromProp).toFixed(0)
        },
        delay: this.delay,
      })
    
    this.tLite = tLite
  }
  
  play () {
    this.tLite?.play()
  }

  pause () {
    this.tLite?.pause()
  }

  restart () {
    this.tLite?.restart()
  }
  
  easeCheck () {
    if (this.countFullstops(this.easing) !== 1) {
      throw new Error('Invalid ease type. (eg. easing="Power1.easeOut")')
    }
    return this.easing
  }
  mounted() {
    this.tween(this.to)
  }
}
</script>
