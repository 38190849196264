import Vue from 'vue'
import Vuex from 'vuex'

import UiModule from './uiStore'
import UserModule from './userStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ui: UiModule,
    user: UserModule
  }
})
