<template>
  <div class="app-nav">
    <div class="container">
      <nav>
        <!-- App Menu -->
        <div class="app-menu">
          <!-- Logo -->
          <router-link to="/" class="logo">
            <img src="/img/logo.svg" />
          </router-link>

          <router-link :class="{ active: $route.name === 'home' }" to="/">Home</router-link>
          <router-link :class="{ active: $route.name === 'mint' }" to="/mint">Mint</router-link>
          <router-link :class="{ active: $route.name === 'purr' }" to="/purr">PURR</router-link>
          <router-link :class="{ active: $route.name === 'build' }" to="/build">Build</router-link>
          <router-link :class="{ active: $route.name === 'faq' }" to="/faq">FAQ</router-link>
        </div>

        <!-- User Menu -->
        <div class="user-menu">
          <!-- Address -->
          <a :href="explorerUrl" target="_blank" class="address" v-if="userStore.address">
            <img :src="`/img/icons/wallet.svg`" />
            {{ getShortAddress(userStore.address) }}  
          </a>

          <!-- Login -->
          <UserLogin v-if="!userStore.address" />

          <!-- Logout -->
          <UserLogout v-if="userStore.address" />
        </div>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import Button from 'primevue/button'
import MintMixin from '@/mixins/mintMixin'
import LinksMixin from '@/mixins/linksMixin'
import UserLogin from '@/components/auth/UserLogin.vue'
import UserLogout from '@/components/auth/UserLogout.vue'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    Button,
    UserLogin,
    UserLogout,
  }
})
export default class AppNav extends Mixins(MintMixin, LinksMixin) {
  get explorerUrl() {
    return `https://etherscan.io/address/${this.userStore.address}`
  }
}
</script>

<style lang="scss">
.app-nav {
  background-color: #fbfaf5;
  border-bottom: 1px solid #dddacc;
  position: sticky;
  z-index: 3;

  nav {
    padding: 1.25rem 0;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .app-menu {
      display: flex;
      align-items: center;

      a {
        margin: 0 1.25rem 0 0;
        padding: .5rem 1.25rem;
        border-radius: 12px;
      }
      
      a.active {
        background: linear-gradient(90deg, rgba(192, 173, 0, 0.1) -10.2%, rgba(20, 182, 0, 0.1) 47.34%, rgba(0, 126, 73, 0.1) 109.67%);
      }

      .logo {
        color: orange;
        font-weight: bold;
        letter-spacing: .15rem;
        margin-right: 2rem;
        padding: 0;

        img {
          display: block;
          height: 2.5rem;
        }
      }
    }
    .user-menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .address {
        display: inline-flex;
        align-items: center;
        margin: 0 1.25rem 0 0;

        img {
          display: block;
          height: 1.25rem;
          margin: 0 .25rem 0 0;
        }
      }
    }

    a {
      color: #333;
      text-decoration: none;
    }
  }
}
</style>