import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { BrowserProvider, EthersError, JsonRpcSigner, makeError } from 'ethers'
import { getUserFromStorage, removeUserFromStorage, saveUserToStorage } from '@/providers/storage'

// eslint-disable-next-line
const w = window as unknown as any

@Module({ namespaced: true, name: 'user' })
export default class UserStore extends VuexModule {
  signer: JsonRpcSigner | null = null
  address = ''
  signature = ''
  provider: null | BrowserProvider = null

  @Mutation
  setProvider(provider: BrowserProvider) {
    this.provider = provider
  }

  @Mutation
  setSigner(signer: JsonRpcSigner) {
    this.signer = signer
  }

  @Mutation
  setAddress(address: string) {
    this.address = address
  }

  @Mutation
  setSignature(signature: string) {
    this.signature = signature
  }

  @Mutation
  clear() {
    this.signer = null
    this.address = ''
    this.signature = ''
    this.provider = null
    removeUserFromStorage()
  }

  @Action
  async preload() {
    const user = getUserFromStorage()

    if (!user) return

    const provider = new BrowserProvider(w.ethereum)
    
    this.setProvider(provider)
    this.setAddress(user.address)
  }

  @Action({ rawError: true })
  async init() {
    try {
      if (w.ethereum == null) {
        // const provider = getDefaultProvider('')
  
        // this.setProvider(provider)
  
        throw makeError('Wallet not installed', 'NOT_IMPLEMENTED')
      } else {
        const provider = new BrowserProvider(w.ethereum)
        
        provider.send("eth_requestAccounts", []).then(async () => {
          const signer = await provider.getSigner()
          const address = await signer.getAddress()
        
          this.setProvider(provider)
          this.setAddress(address)

          saveUserToStorage(address)
        })
      }
    } catch (error: unknown) {
      throw error as EthersError
    }
  }

  @Action({ rawError: true })
  async signMessage(message: string) {
    try {
      if (!this.provider) throw new Error('Wallet is not connected')

      const signer = await this.provider.getSigner()
      const signature = await signer.signMessage(message)
      
      // const isValid = await ApiProvider.validateSignature(address, signature)
      // const isValid = true

      // if (!isValid) {
        // throw makeError('Invalid signature', 'INVALID_ARGUMENT')
      // }

      // this.setSigner(signer)
      // this.setOwnerAddress(address)
      // this.setSignature(signature)

      return signature
    } catch (error: unknown) {
      throw error as EthersError
    }
  }
}