<template>
  <div class="home container">
    <!-- About Game -->
    <div class="legend box">
      <div class="stats">
        <div class="total">
          <!-- Left Cat -->
          <img class="coin" :src="`/img/cat-orange.svg`" />

          Total ETH staked: 
          <strong>$
            <NumberAnimation
              class="bold transition"
              :from="stats"
              :to="stats"
              :duration="2"
              :format="commify"
              easing="Power1.easeOut"
            />
          </strong>

          <!-- Right Cat -->
          <img class="coin" :src="`/img/cat-brown.svg`" />
        </div>
        
        <!-- Button -->
        <a class="p-button p-button-xl" :href="refUrl" target="_blank">Join Staking for Yields and Points</a>
      </div>
    </div>

    <div class="movie">
      <a 
        :key="index"
        v-for="(event, index) in events"
        class="event"
        :class="[event.type, `cloud${event.cloud}`]"
        :style="{
          zIndex: event.paused ? 2 : 1,
          marginTop: event.shift + 'vh',
          animationPlayState: event.paused ? 'paused' : 'running',
          animationDuration: event.animationDuration + 's'
        }"
        @mouseover="stopAnimation(event.id)"
        @mouseout="resumeAnimation(event.id)"
        
        :href="getEthereumExplorerTxUrl(event.payload.log.transactionHash)" 
        target="_blank"
      >
        <!-- Amount -->
        <template v-if="event.type === 'deposit'">+</template>
        <template v-if="event.type === 'withdraw'">-</template>
        
        {{ format(event.amount) }}
        <img class="coin" :src="`/img/coins/${event.token.toLowerCase()}.svg`" />
        <!-- {{ getTokenLabel(event.token) }} -->
    </a>
      <img class="world" src="/img/pages/home/world.webp" />
    </div>
  </div>
</template>

<script lang="ts">
import abi from '@/data/staking.abi'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import MintMixin from '@/mixins/mintMixin'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import AnimationMixin from '@/mixins/animationMixin'
import { Component, Mixins } from 'vue-property-decorator'
import NumberAnimation from '@/components/common/NumberAnimation.vue'
import { Contract, TransactionResponse, WebSocketProvider, formatEther } from 'ethers'
import { ZircuitStakingEvent, ZircuitStakingDeposit, ZircuitStakingWithdraw, ZircuitStakingCustomEvent } from '@/types/app'

@Component({
  components: {
    Dialog,
    Button,
    Accordion,
    AccordionTab,
    NumberAnimation
  }
})
export default class PlayView extends Mixins(MintMixin, AnimationMixin) {
  stats = '0'
  height = 0
  price = 0.001
  progress = false
  dialogVisible = false
  tx: TransactionResponse | null = null
  events: ZircuitStakingCustomEvent[] = []
  
  get contractAddress() {
    return `${process.env.VUE_APP_GAME_CONTRACT_ADDRESS}`
  }

  getCloudType() {
    return this.randomIntFromInterval(1, 5)
  }

  getCloudShiftV() {
    return this.randomIntFromInterval(0, 40)
  }

  getCloudAnimationSpeed() {
    return this.randomIntFromInterval(80, 220)
  }

  getTokenLabel(token: string) {
    if (token === '0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7') return 'rsETH'
    if (token === '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0') return 'wstETH'
    if (token === '0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee') return 'weETH'
    if (token === '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2') return 'ETH'
    if (token === '0xf951E335afb289353dc249e82926178EaC7DEd78') return 'swETH'
    if (token === '0xd5F7838F5C461fefF7FE49ea5ebaF7728bB0ADfa') return 'mETH'
    if (token === '0xbf5495Efe5DB9ce00f80364C8B423567e58d2110') return 'ezETH'
    if (token === '0x8c1BEd5b9a0928467c9B1341Da1D7BD5e10b6549') return 'lsETH'
    if (token === '0x6ef3D766Dfe02Dc4bF04aAe9122EB9A0Ded25615') return 'primeETH'
    if (token === '0xD9A442856C234a39a81a089C06451EBAa4306a72') return 'pufETH'
    if (token === '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0') return 'rswETH'
    if (token === '0x49446A0874197839D15395B908328a74ccc96Bc0') return 'mstETH'
    if (token === '0x32bd822d615A3658A68b6fDD30c2fcb2C996D678') return 'mswETH'
    if (token === '0xE46a5E19B19711332e33F33c2DB3eA143e86Bc10') return 'mwBETH'
    if (token === '0x4c9EDD5852cd905f086C759E8383e09bff1E68B3') return 'USDe'
    if (token === '0x4d831e22F062b5327dFdB15f0b6a5dF20E2E3dD0') return 'cSTONE'

    return 'ETH'
  }

  randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  mounted() {
    this.getStats()
    this.listenEvents()
    this.getLatestDeposits()

    setInterval(this.getStats, 15000)
  }

  format(num: string) {
    return Number(formatEther(num)).toFixed(2)
  }

  commify(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  async getLatestDeposits() {
    const deposits = await this.api.getZircuitLatestDeposits()

    deposits.map(item => {
      const timeout = this.randomIntFromInterval(10000, 50000)
      const evt = {
        id: Number(item.id),
        address: item.depositor,
        amount: item.amount,
        token: item.token,
        payload: {
          log: {
            transactionHash: item.transactionHash
          }
        },
        type: 'deposit',
        timestamp: Date.now(),
        shift: this.getCloudShiftV(),
        animationDuration: this.getCloudAnimationSpeed(),
        cloud: this.getCloudType(),
        paused: false,
      }

      if (this.events.length) {
        setTimeout(() => {
          this.events.push(evt)
        }, timeout)
      } else {
        this.events.push(evt)
      }

      this.setEventKill(evt.id)
    })
  }

  async getStats() {
    try {
      const stats = await this.api.getZircuitStats()
      const fixed = Number(stats).toFixed(0)
      
      this.stats = fixed
    } catch(e) {
      console.error(e)
    }
  }

  async listenEvents() {
    // initWebSocket(WebSocket, {
      // url: this.chainWssUrl, 
      // initEventListeners: this.initEventListeners
    // })
    this.initEventListeners()
  }
  
  initEventListeners() {
    const provider = new WebSocketProvider(this.chainWssUrl)
    const contract = new Contract(this.stakingContractAddress, abi, provider)

    contract.on(ZircuitStakingEvent.Deposit, this.onDeposit)
    contract.on(ZircuitStakingEvent.Withdraw, this.onWithdraw)
  }

  onDeposit(...event: ZircuitStakingDeposit) {
    this.addBlock(event, 'deposit')
  }

  onWithdraw(...event: ZircuitStakingWithdraw) {
    this.addBlock(event, 'withdraw')
  }

  addBlock(event: ZircuitStakingDeposit | ZircuitStakingWithdraw, type: 'deposit' | 'withdraw') {
    const now = Date.now()
    const timeout = 5000
    const evt = {
      id: Number(event[0]),
      address: event[1],
      token: event[2],
      amount: event[3],
      payload: event[4],
      type: type,
      timestamp: now,
      shift: this.getCloudShiftV(),
      animationDuration: this.getCloudAnimationSpeed(),
      cloud: this.getCloudType(),
      paused: false
    }

    if (this.events.length && now - this.events[this.events.length - 1].timestamp < timeout) {
      setTimeout(() => {
        this.events.push(evt)
      }, timeout)
    } else {
      this.events.push(evt)
    }

    this.setEventKill(evt.id)
  }

  setEventKill(id: number) {
    const timeout = 300 * 1000

    setTimeout(() => {
      const index = this.events.findIndex(e => e.id === id)
      
      this.events.splice(index, 1)
    }, timeout)
  }

  stopAnimation(eventId: number) {
    const event = this.events.find(item => item.id === eventId)

    if (!event) return

    event.paused = true
  }

  resumeAnimation(eventId: number) {
    const event = this.events.find(item => item.id === eventId)

    if (!event) return

    event.paused = false
  }
}
</script>

<style lang="scss">
.home {
  padding-top: 2rem;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-event {
    from {
      transform: rotate(-90deg);
    }

    to {
      transform: rotate(90deg);
    }
  }

  @keyframes toRight {
    from {
      margin-left: -25rem;
    }

    to {
      margin-left: 2500px;
    }
  }

  @keyframes toLeft {
    from {
      margin-right: -25rem;
    }

    to {
      margin-right: 2500px;
    }
  }

  h3 {
    padding-bottom: 1.25rem;
  }

  p {
    line-height: 1.32;
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;

    p {
      text-align: center;
      padding: 1.5rem 0 1rem;
    }

    .stats {
      flex-grow: 1;
      text-align: center;
      font-size: 2rem;

      .total {
        margin: 0 0 .8rem 0;
      }

      strong {
        min-width: 16rem;
        display: inline-block;
        text-align: left;
      }

      img {
        width: 48px;
        margin: 0 1rem;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .movie {
    margin: 1rem -1.5rem 0;
    height: calc(100vh - 20rem);
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .event {
      position: fixed;
      z-index: 2;
      width: 25rem;
      height: 8rem;
      animation-iteration-count: infinite;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.1rem;
      text-decoration: none;

      .coin {
        width: 24px;
        height: 24px;
        display: block;
        margin: 0 .5rem;
      }

      &.cloud1 {
        background-image: url(./../../public/img/pages/home/cloud1.webp);
        justify-content: flex-end;
        padding-right: 6rem;
        height: 4rem;
      }

      &.cloud2 {
        background-image: url(./../../public/img/pages/home/cloud2.webp);
        justify-content: flex-end;
        padding-right: 8rem;
        height: 5rem;
      }

      &.cloud3 {
        background-image: url(./../../public/img/pages/home/cloud3.webp);
        justify-content: flex-start;
        padding-left: 7rem;
        height: 4.5rem;
      }

      &.cloud4 {
        background-image: url(./../../public/img/pages/home/cloud4.webp);
        justify-content: flex-start;
        padding-left: 7rem;
      }
      
      &.cloud5 {
        background-image: url(./../../public/img/pages/home/cloud5.webp);
        justify-content: flex-start;
        padding-left: 8rem;
        height: 4rem;
      }


      &.deposit {
        left: 0;
        // color: #2a7e21;
        animation-name: toRight;

        a {
          // color: #2a7e21;
        }
      }

      &.withdraw {
        right: 0;
        // color: rgb(129, 16, 16);
        animation-name: toLeft;

        a {
          // color: rgb(129, 16, 16);
        }
      }
    }

    .pipe {
      position: absolute;
      left: 0;
      top: calc(50% - 1rem);
      width: 100%;
    }

    .world {
      position: fixed;
      width: 95vw;
      animation: spin 100s infinite;
      bottom: calc(0% - 60vw);
    }
  }
}
</style>