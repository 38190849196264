import { ZircuitStakingDepositGrapItem } from '@/types/app'
import axios from 'axios'

export class ApiProvider {
  get apiBaseUrl () {
    return process.env.VUE_APP_API_BASE_URL
  }

  get client() {
    return axios.create({
      baseURL: this.apiBaseUrl,
      headers: {
        accept: 'application/json',
      }
    })
  }

  async time() {
    return this.client.get('/api/status/time')
  }

  async getZircuitStats(): Promise<string> {
    const { data } = await this.client.get('/v1/zircuit/staking/stats')

    return data
  }

  async getTestnetSupply() {
    const { data } = await this.client.get('/v1/zircuit/testnet/supply')

    return data
  }

  async getZircuitLatestDeposits(): Promise<ZircuitStakingDepositGrapItem[]> {
    const { data } = await this.client.get('/v1/zircuit/staking/latest')

    return data
  }

  async getStakingSupply() {
    const { data } = await this.client.get('/v1/zircuit/staking/supply')

    return data
  }

  async getPointsSupply() {
    const { data } = await this.client.get('/v1/zircuit/points/supply')

    return data
  }

  async getTestnetEligibility(address: string) {
    const { data } = await this.client.get(`/v1/zircuit/testnet/eligibility/${address}`)

    return data
  }

  async getStakingEligibility(address: string) {
    const { data } = await this.client.get(`/v1/zircuit/staking/eligibility/${address}`)

    return data
  }

  async getPointsEligibility(address: string) {
    const { data } = await this.client.get(`/v1/zircuit/points/eligibility/${address}`)

    return data
  }

  async getOgEligibility(address: string) {
    const { data } = await this.client.post('/v1/zircuit/og/eligibility', { address })

    return data
  }

  async generateSignature(tokenId: number, signature: string) {
    const { data } = await this.client.post('/v1/signature', { tokenId, signature })

    return data
  }
}

export default new ApiProvider()