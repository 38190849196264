<template>
  <div class="add-network">
    <Button class="p-button-xs" @click="add" :label="`Add ${chainName}`" />
  </div>
</template>

<script lang="ts">
import Button from 'primevue/button'
import MintMixin from '@/mixins/mintMixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    Button,
  },
})
export default class UserLogin extends Mixins(MintMixin) {
  async add() {
    try {
      await this.addNetwork()
    } catch (error: unknown) {
      // eslint-disable-next-line
      this.showError((error as any).message)
    } 
  }
}
</script>

<style lang="scss">
.add-network {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>