<template>
  <div class="user-login">
    <Button class="p-button-xs" @click="connect" label="Connect Wallet" />
  </div>
</template>

<script lang="ts">
import { EthersError } from 'ethers'
import Button from 'primevue/button'
import BaseMixin from '@/mixins/baseMixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    Button,
  },
})
export default class UserLogin extends Mixins(BaseMixin) {
  async connect() {
    try {
      await this.userStore.init()
    } catch (error: unknown | EthersError) {
      this.showError((error as EthersError).shortMessage)
    }
  }
}
</script>

<style lang="scss">
.user-login {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>