const ADDRESS_KEY = 'APP_AUTH_ADDRESS'
const USER_EXPIRATION_KEY = 'APP_AUTH_USER_EXPIRATION'
const EXPIRATION = 1000 * 60 * 60 * 24 * 30 // 30 days

export function saveUserToStorage(address: string) {
  const now = Date.now()

  localStorage.setItem(USER_EXPIRATION_KEY, `${now + EXPIRATION}`)
  localStorage.setItem(ADDRESS_KEY, address)
}

export function getUserFromStorage() {
  const now = Date.now()
  const expiration = Number(localStorage.getItem(USER_EXPIRATION_KEY))

  // Logged out
  if (!expiration) return null

  // Expired, clear expiration, clear user
  if (now - EXPIRATION > expiration) {
    removeUserFromStorage()

    return null
  }

  const address = localStorage.getItem(ADDRESS_KEY)

  if (address) return { address }
  
  return null
}

export function removeUserFromStorage() {
  localStorage.removeItem(ADDRESS_KEY)
  localStorage.removeItem(USER_EXPIRATION_KEY)
}