import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'ui' })
export default class UiStore extends VuexModule {
  error: unknown = null
  loading = false

  @Mutation
  setError(error: unknown) {
    this.error = error
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload
  }
}