<template>
  <div id="app" class="app">
    <!-- Header -->
    <AppHeader />

    <div class="app-content">
      <!-- View -->
      <router-view/>

      <!-- Global Loading -->
      <AppLoading />
    </div>

    <!-- Global Error -->
    <Toast position="bottom-center" group="errors"  />
    
    <!-- Footer -->
    <AppFooter />
  </div>
</template>

<script lang="ts">
import Toast from 'primevue/toast'
import BaseMixin from './mixins/baseMixin'
import AnimationMixin from './mixins/animationMixin'
import AppHeader from '@/components/common/AppHeader.vue'
import AppFooter from '@/components/common/AppFooter.vue'
import AppLoading from '@/components/common/AppLoading.vue'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    Toast,
    AppHeader,
    AppFooter,
    AppLoading
  }
})
export default class App extends Mixins(BaseMixin, AnimationMixin) {
  async mounted() {
    this.$nextTick(async () => {
      await this.userStore.preload()
    })
  }
}
</script>

<style lang="scss">
.app {
  background: #f4f1e5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  line-height: 1.32;

  .app-content {
    flex-grow: 1;
    margin: 0 auto;
    width: 100%;
    overflow: auto;
    position: relative;
  }

  > i {
    animation: bang .75s ease-out forwards;
    position: absolute;
    z-index: 9999;
    display: block;
    opacity: 0;
    background: url(./../public/img/logo.svg);
    background-size: contain;
    pointer-events: none
  }
}

@keyframes bang {
  from {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}
</style>
