import abi from '@/data/purr.abi'
import BaseMixin from "./baseMixin"
import { ChainId } from '@/types/blockchain'
import Button from 'primevue/button'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { EligibilityStatus, NftItem } from '@/types/app'
import { Contract, BrowserProvider } from 'ethers'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    Button,
    Accordion,
    AccordionTab
  }
})
export default class MintMixin extends Mixins(BaseMixin) {
  activeChainId = '0x1'
  addNetworkButtonRequired = true
  progressItem = -1
  active = 1
  badges: {
    [key: string]: NftItem
  } = {}

  @Prop() disabled!: boolean

  get window() {
    // eslint-disable-next-line
    return window as any
  }

  get chainId() {
    return process.env.VUE_APP_CHAIN_ID
  }

  get chainIdHex() {
    return `0x${Number(process.env.VUE_APP_CHAIN_ID).toString(16)}`
  }

  get chainName() {
    return process.env.VUE_APP_CHAIN_NAME
  }

  get chainNativeCurrencyName() {
    return `${process.env.VUE_APP_CHAIN_NATIVE_CURRENCY_NAME}`
  }

  get chainNativeCurrencySymbol() {
    return `${process.env.VUE_APP_CHAIN_NATIVE_CURRENCY_SYMBOL}`
  }

  get chainNativeCurrencyAddress() {
    return '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
  }

  get chainNativeCurrencyDecimals() {
    return Number(process.env.VUE_APP_CHAIN_NATIVE_CURRENCY_DECIMALS)
  }

  get chainRpcUrl() {
    return `${process.env.VUE_APP_CHAIN_RPC_URL}`
  }

  get chainWssUrl() {
    return `${process.env.VUE_APP_CHAIN_WSS_ETH_URL}`
  }

  get mintContractAddress() {
    return `${process.env.VUE_APP_MINT_CONTRACT_ADDRESS}`
  }

  get stakingContractAddress() {
    return `${process.env.VUE_APP_STAKING_CONTRACT_ADDRESS}`
  }

  get clientId() {
    return '6d9aed494296f225df610f0a81ffea17'
  }

  get ethereumExplorerBaseUrl() {
    return process.env.VUE_APP_ETHEREUM_EXPLORER_BASE_URL
  }

  get zircuitExplorerBaseUrl() {
    return process.env.VUE_APP_ZIRCUIT_EXPLORER_BASE_URL
  }

  @Watch('userStore.provider', { immediate: true })
  async onProviderChange() {
    this.addNetworkButtonRequired = true

    try {
      const network = await this.userStore.provider?.getNetwork()
      
      if (Number(network?.chainId) === Number(this.chainId)) {
        this.addNetworkButtonRequired = false
      }
    } catch (error: unknown) {
      console.log(error)
    } 
  }

  mounted() {
    this.listenChain()
  }

  async listenChain() {
    const chainId = await this.window.ethereum.request({ method: "eth_chainId" })

    this.activeChainId = chainId
    
    this.window.ethereum.on('chainChanged', (chainId: string) => {
      this.activeChainId = chainId
      this.addNetworkButtonRequired = true

      if (chainId === this.chainIdHex) {
        this.addNetworkButtonRequired = false
      }
    })
  }

  toggleActive() {
    this.active = !this.active ? 1 : 0
  }

  getChainById(id: ChainId) {
    return ChainId[id]
  }

  getEthereumExplorerTxUrl(hash: string) {
    return `${this.ethereumExplorerBaseUrl}/tx/${hash}`
  }

  getZircuitExplorerTxUrl(hash: string) {
    return `${this.zircuitExplorerBaseUrl}/tx/${hash}`
  }

  getZircuitExplorerAddressUrl(address: string) {
    return `${this.zircuitExplorerBaseUrl}/address/${address}`
  }

  getMintLabel(eligibility: EligibilityStatus) {
    if (!this.userStore.address) return 'Connect wallet'
    if (eligibility === EligibilityStatus.NotEligible) return 'Not Eligible'
    if (eligibility === EligibilityStatus.Eligible) return 'Mint'
    if (eligibility === EligibilityStatus.Owned) return '✨ Minted!'
  }

  // eslint-disable-next-line
  getMintRequest(payload: any) {
    return [
      payload.to,
      payload.royaltyRecipient,
      payload.royaltyBps.hex,
      payload.primarySaleRecipient,
      payload.tokenId.hex,
      payload.uri,
      payload.quantity.hex,
      payload.price,
      payload.currencyAddress,
      payload.mintStartTime.hex,
      payload.mintEndTime.hex,
      payload.uid
    ]
  }

  async addNetwork() {
    const params = {
      chainId: this.chainIdHex,
      chainName: this.chainName,
      nativeCurrency: {
          name: this.chainNativeCurrencyName,
          symbol: this.chainNativeCurrencySymbol,
          decimals: this.chainNativeCurrencyDecimals
      },
      rpcUrls: [this.chainRpcUrl],
      blockExplorerUrls: [this.zircuitExplorerBaseUrl]
    }

    try {
      await this.window.ethereum.request({ 
        method: 'wallet_addEthereumChain',
        params: [params, this.userStore.address]
      })

      this.addNetworkButtonRequired = false
    } catch (error: unknown) {
      // eslint-disable-next-line
      this.showError((error as any).message)
    }
  }

  async switchNetwork() {
    try {
      await this.window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: this.chainIdHex }]
      })
    } catch (error: unknown) {
      // eslint-disable-next-line
      if ((error as any).code === 4902) {
        try {
          await this.addNetwork()
        } catch (error: unknown) {
          // eslint-disable-next-line
          this.showError((error as any).message)
        }
      }
    }
  }

  async mint(item: NftItem) {
    this.progressItem = item.tokenId

    try {
      await this.switchNetwork()
      
      if (this.activeChainId !== this.chainIdHex) throw ('Please switch to Zircuit testnet, meow!')

      const message = `${item.name}:${item.desc}`
      const provider = new BrowserProvider(this.window.ethereum, 'any')
      const signer = await provider.getSigner()
      const contract = new Contract(this.mintContractAddress, abi, signer)
      const sig = await this.userStore.signMessage(message)
      
      const { signature, payload, error } = await this.api.generateSignature(item.tokenId, sig)

      if (error) return this.showError(error)
      
      const request = this.getMintRequest(payload)
      const txRequest = await contract.mintWithSignature(request, signature, { gasLimit: 1000000 })
      const tx = await txRequest.wait()

      this.bus.$emit('mint:success', { tx, item })
    } catch (error: unknown) {
      // eslint-disable-next-line
      const e = error as any

      if (e.code === 'ACTION_REJECTED') return
      if (e.code === 'NETWORK_ERROR') return
      
      if (e.shortMessage) return this.showError(e.shortMessage)
      if (e.message) return this.showError(e.message)
      
      this.showError(e)
    } finally {
      this.progressItem = -1
    }
  }
}